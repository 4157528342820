@import './variables.scss';

.cont__form {
    width: 85%;
    border-top: 2px solid #8bc61c;
    margin-top: 5%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;

    & > img {
        width: 100px;
        object-fit: contain;
    }

    & > h2 {
        font-size: 18px;
        color: #666;
        font-weight: 500;
    }

    & a {
        color: #8bc61c;
        text-decoration: none;
        transition: border-bottom .7s;
        
        &:hover {
            border-bottom: 2px solid #8bc61c;
        }
    }

    & > input, textarea {
        width: 70%;
        margin: 15px 0;
        padding: 10px 15px;
        border-radius: 15px;
        border: .8px solid #8bc61c;
        transition: transform .7s;

        @media(max-width: 550px){
            width: 95%;
        }

        &[type="submit"]{
            min-width: 200px;
            width: fit-content;
            background-color: transparent;
            color: #8bc61c;
            cursor: pointer;
            transition: all .6s;
            text-transform: uppercase;
            font-weight: bold;
            margin-top: 10px;

            &:hover {
                background-color: $second_color;
                border: .8px solid $second_color;
                color: #fff;
            }
        }

        &::placeholder {
            color: #666;
        }

        &:focus {
            outline: none;
            transform: translateY(-5px);
        }
    }
}

.cont__contactPage {
    width: 85%;
    margin: 2.5% auto;
    display: flex;
    justify-content: space-between;

    @media(max-width: 550px){
        flex-direction: column;
    }

    &_moduleContact {
        width: 60%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        @media(max-width: 550px){
            width: 100%;
            align-items: center;
        }
    }

    &_information {
        width: 40%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        @media(max-width: 550px){
            width: 100%;
            align-items: center;
        }

        & > h2 {
            color: #8bc61c;
            text-transform: uppercase;
            font-weight: 500;
        }

        &_mission {
            width: 100%;
            height: fit-content;
            background-color: #8bc61c;
            padding: 35px;
            color: #fff;
            text-align: left;
            line-height: 50px;
            box-shadow: 0px 3px 5px #dedede;
            font-weight: bold;
        }

        &_contact {
            width: 100%;
            display: flex;
            flex-direction: column;

            & > li {
                display: flex;
                align-items: center;
                margin: 2.5% 0;

                & > img {
                    width: 30px;
                    object-fit: contain;
                }

                & > a {
                    color: #8bc61c;
                    text-decoration: none;
                    font-size: 12px;
                    margin-left: 15px;
                    font-weight: bold;
                    transition: transform .8s;

                    &:hover {
                        transform: scale(.9);
                    }
                }
            }
        }
    }
}