.cont__footer {
  width: 100%;
  height: fit-content;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 550px) {
  .cont__footer {
    flex-direction: column;
  }
}
.cont__footer_mission {
  width: 100%;
  height: fit-content;
  padding: 35px;
  background-color: #003f95;
  margin-top: 5%;
  box-shadow: inset 2px 3px 3px #6e66668c;
}
.cont__footer_mission > h2, .cont__footer_mission p {
  color: #fff;
}
.cont__footer_mission > p {
  font-size: 21px;
  line-height: 25px;
}
.cont__footer img {
  width: 250px;
  object-fit: contain;
}
@media (max-width: 550px) {
  .cont__footer img {
    width: 225px;
  }
}
.cont__footer ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-inline-start: 0;
}
@media (max-width: 550px) {
  .cont__footer ul {
    flex-direction: column;
    padding: 15px 0;
  }
}
.cont__footer ul > li {
  margin: 0 2.5%;
  width: 25%;
}
@media (max-width: 550px) {
  .cont__footer ul > li {
    width: 95%;
    margin: 2.5% 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
.cont__footer ul > li a[id=preventivo] {
  background-color: transparent;
  color: #8bc61c;
  border: 1px solid #8bc61c;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 2px 2px 3px #dedede;
  cursor: pointer;
  transition: all 0.6s;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 10px;
}
.cont__footer ul > li a[id=preventivo]:hover {
  background-color: #8bc61c;
  color: #fff;
}
.cont__footer ul > li[id=info] {
  flex-direction: column;
  font-size: 11px;
}
.cont__footer ul > li[id=info] a {
  margin: 0;
  font-weight: bold;
}
.cont__footer ul > li[id=info] > img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin-bottom: 2%;
}
.cont__footer ul > li a {
  text-decoration: none;
  color: #8bc61c;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
  margin: 10px 0;
}
.cont__footer ul > li a:hover {
  transform: translateX(5px);
}
.cont__footer ul > li a > img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.cont__footer ul > li a > span {
  margin-left: 5px;
  font-weight: bold;
}

