.cont__services {
    width: 85%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding-inline-start: 0;

    @media(max-width: 550px){
        flex-direction: column;
        width: 95%;
    }

    & > a {
        text-decoration: none;
        width: 30%;
        color: #1d1d1d;

        @media(max-width: 550px){
            width: 100%;
        }

        & > li {
            width: 100%;
            min-height: 250px;
            height: fit-content;
            border-radius: 15px;
            border: .8px solid #8bc61c;
            box-shadow: 2px 2px 4px #dedede;
            padding: 10px 20px;
            margin: 5% 0;
            transition: transform .8s ease-in-out, box-shadow .7s ease-in-out;
            cursor: pointer;
    
            &:hover {
                transform: scale(.9);
                box-shadow: .5px 1px 2px #8bc61c;
            }

            & > h3 {
                color: #8bc61c;
                text-align: left;
                font-size: 16px;
            }
    
            & > p {
                text-align: left;
                line-height: 30px;
                font-weight: 500;
            }
        }
    }
}