$main_color: #8bc61c;
$second_color: #003f95;

@mixin sectionInside() {
    width: 85%;
    margin: 2.5% auto;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-inline-start: 0;
    list-style: none;

    @media(max-width: 550px){
        width: 95%;
        margin: 10% auto;
        flex-direction: column;
        align-items: center;
    }
}

@mixin sectionDescription() {
    width: 85%;
    height: fit-content;
    margin: 3% 0;
    padding: 35px;
    border-radius: 15px;
    border: .8px solid #8bc61c;
    box-shadow: 2px 2px 4px #8bc61c;

    @media(max-width: 550px){
        width: 95%;
        margin: 10% auto;
        padding: 20px;
    }

    & > h2 {
        text-transform: uppercase;
        text-align: left;
        color: #1d1d1d;
    }

    & > p {
        color: #1d1d1d;
        text-align: left;
        font-weight: 500;
    }
}

@mixin sectionColored($padding, $color) {
    width: 100%;
    height: fit-content;
    padding: $padding;
    background-color: $color;
    margin-top: 5%;
    box-shadow: inset 2px 3px 3px #6e66668c;
}