.cont__intro {
  width: 100%;
  height: 65vh;
  background-color: #8bc61c;
  box-shadow: 0px 4px 3px #dedede;
}
@media (max-width: 550px) {
  .cont__intro {
    height: 50vh;
  }
  .cont__intro_slide {
    height: 50vh;
  }
}
.cont__intro_slide {
  width: 100%;
  height: 65vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center center;
}
.cont__intro_slide h2 {
  color: #fff;
  font-size: 36px;
  text-transform: uppercase;
  width: 60%;
  text-align: left;
  text-shadow: 0px 2px 3px #1d1d1d;
  line-height: 60px;
}
.cont__intro_slide h2 span {
  color: #8bc61c;
}
.cont__intro_slide h2, .cont__intro_slide a {
  margin-left: 10%;
}
.cont__intro_slide a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 35px;
  border: 2px solid #fff;
  border-radius: 15px;
  transition: background-color 1s;
  box-shadow: 1px 3px 2px #1d1d1d;
  max-width: 200px;
}
.cont__intro_slide a:hover {
  background-color: #003f95;
}
@media (max-width: 550px) {
  .cont__intro_slide {
    height: 50vh;
  }
}

