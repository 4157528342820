@import './variables.scss';

.cont__insideService {
    width: 100%;
    height: fit-content;
    opacity: 0;
    transform: translateX(-101%);
    animation-name: appear;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_description {
        @include sectionDescription();

        & > ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            & > li {
                width: 100%;
                text-align: left;
                font-size: 16px;

                &::before {
                    content: "\2022";
                    color: $main_color;
                    font-weight: bold;
                    width: 1em;
                    font-size: 18px;
                    margin-right: 5px;
                }
            }
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}