@import './variables.scss';

.cont__home {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_sectionDescription {
        @include sectionDescription();
    }

    &_sectionCollabs {
        @include sectionInside();

        &_image {
            width: 50px;
            object-fit: contain;
            margin-top: 2.5%;

            & + h2 {
                color: #8bc61c;
                text-transform: uppercase;
                font-size: 18px;
                margin-top: 25px;
            }
        }

        & > li {
            width: 15%;
            height: 100px;
            margin: 2.5% 0;

            @media(max-width: 550px){
                width: 80%;
            }

            & > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &_sectionNews {
        @include sectionInside();
        margin: 2.5% auto;

        & > li {
            width: 30%;
            height: 225px;
            margin: 2.5% 0;
            box-shadow: 2px 3px 5px #dedede;
            padding: 5px 0;
            transition: transform .8s ease-in-out, box-shadow .7s ease-in-out;
            cursor: pointer;

            @media(max-width: 550px){
                width: 90%;
                margin: 10% 0;
            }
    
            &:hover {
                transform: scale(.9);
                box-shadow: .5px 1px 2px #dedede;
            }

            & > a > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &_sectionFAQ {
        @include sectionInside();

        & > li {
            width: 30%;
            min-height: 250px;
            height: fit-content;
            margin: 2.5% auto;
            padding: 5px 15px;

            @media(max-width: 550px){
                width: 95%;
            }

            &[id="inside"]{
                box-shadow: 0px 4px 3px #dedede;
            }

            & > h3 {
                color: #8bc61c;
                text-transform: uppercase;
                font-size: 16px;
            }

            & > p {
                color: #666;
                font-size: 14px;
            }
        }
    }
}