@import './variables.scss';

.cont__about {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_description {
       @include sectionDescription()
    }

    &_extraImage {
        width: 50px;
        object-fit: contain;
        margin-top: 5%;
    }

    &_enti {
        width: 65%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline-start: 0;
        list-style: none;

        @media(max-width: 550px){
            flex-direction: column;
        }

        & > li {
            width: 45%; 

            @media(max-width: 550px){
                width: 95%;
            }

            & > p {
                text-align: center;
            }

            & > img {
                width: 200px;
                height: 125px;
                object-fit: contain;
            }
        }
    }

    &_certificazioni {
        width: 85%;
        height: fit-content;
        padding-inline-start: 0%;
        list-style: none;
        justify-content: space-between;
        align-items: center;
        display: flex;

        @media(max-width: 550px){
            flex-direction: column;

            & > li {
                width: 100% !important;
            }
        }

        & > li {
            width: 20%;

            & > img {
                width: 100%;
                object-fit: contain;
            }
        }
    }

    &_certificationList {
        @include sectionColored(50px, $second_color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media(max-width: 550px){
            padding: 25px 35px;
        }

        & > li {
            margin: 10px 0;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            
            @media(max-width: 550px){
                width: 80%;
            }

            & > ul {
                padding-inline-start: 50px !important;

                @media(max-width: 550px){
                    padding-inline-start: 0 !important;
                }
            }
        }
        
    }
}