@charset "UTF-8";
.cont__insideService {
  width: 100%;
  height: fit-content;
  opacity: 0;
  transform: translateX(-101%);
  animation-name: appear;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont__insideService_description {
  width: 85%;
  height: fit-content;
  margin: 3% 0;
  padding: 35px;
  border-radius: 15px;
  border: 0.8px solid #8bc61c;
  box-shadow: 2px 2px 4px #8bc61c;
}
@media (max-width: 550px) {
  .cont__insideService_description {
    width: 95%;
    margin: 10% auto;
    padding: 20px;
  }
}
.cont__insideService_description > h2 {
  text-transform: uppercase;
  text-align: left;
  color: #1d1d1d;
}
.cont__insideService_description > p {
  color: #1d1d1d;
  text-align: left;
  font-weight: 500;
}
.cont__insideService_description > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cont__insideService_description > ul > li {
  width: 100%;
  text-align: left;
  font-size: 16px;
}
.cont__insideService_description > ul > li::before {
  content: "•";
  color: #8bc61c;
  font-weight: bold;
  width: 1em;
  font-size: 18px;
  margin-right: 5px;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

