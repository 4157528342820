.cont__form {
  width: 85%;
  border-top: 2px solid #8bc61c;
  margin-top: 5%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}
.cont__form > img {
  width: 100px;
  object-fit: contain;
}
.cont__form > h2 {
  font-size: 18px;
  color: #666;
  font-weight: 500;
}
.cont__form a {
  color: #8bc61c;
  text-decoration: none;
  transition: border-bottom 0.7s;
}
.cont__form a:hover {
  border-bottom: 2px solid #8bc61c;
}
.cont__form > input, .cont__form textarea {
  width: 70%;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 15px;
  border: 0.8px solid #8bc61c;
  transition: transform 0.7s;
}
@media (max-width: 550px) {
  .cont__form > input, .cont__form textarea {
    width: 95%;
  }
}
.cont__form > input[type=submit], .cont__form textarea[type=submit] {
  min-width: 200px;
  width: fit-content;
  background-color: transparent;
  color: #8bc61c;
  cursor: pointer;
  transition: all 0.6s;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 10px;
}
.cont__form > input[type=submit]:hover, .cont__form textarea[type=submit]:hover {
  background-color: #003f95;
  border: 0.8px solid #003f95;
  color: #fff;
}
.cont__form > input::placeholder, .cont__form textarea::placeholder {
  color: #666;
}
.cont__form > input:focus, .cont__form textarea:focus {
  outline: none;
  transform: translateY(-5px);
}

.cont__contactPage {
  width: 85%;
  margin: 2.5% auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 550px) {
  .cont__contactPage {
    flex-direction: column;
  }
}
.cont__contactPage_moduleContact {
  width: 60%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .cont__contactPage_moduleContact {
    width: 100%;
    align-items: center;
  }
}
.cont__contactPage_information {
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .cont__contactPage_information {
    width: 100%;
    align-items: center;
  }
}
.cont__contactPage_information > h2 {
  color: #8bc61c;
  text-transform: uppercase;
  font-weight: 500;
}
.cont__contactPage_information_mission {
  width: 100%;
  height: fit-content;
  background-color: #8bc61c;
  padding: 35px;
  color: #fff;
  text-align: left;
  line-height: 50px;
  box-shadow: 0px 3px 5px #dedede;
  font-weight: bold;
}
.cont__contactPage_information_contact {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cont__contactPage_information_contact > li {
  display: flex;
  align-items: center;
  margin: 2.5% 0;
}
.cont__contactPage_information_contact > li > img {
  width: 30px;
  object-fit: contain;
}
.cont__contactPage_information_contact > li > a {
  color: #8bc61c;
  text-decoration: none;
  font-size: 12px;
  margin-left: 15px;
  font-weight: bold;
  transition: transform 0.8s;
}
.cont__contactPage_information_contact > li > a:hover {
  transform: scale(0.9);
}

label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

