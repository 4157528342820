.cont__service {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont__service_description {
  width: 85%;
  height: fit-content;
  margin: 3% 0;
  padding: 35px;
  border-radius: 15px;
  border: 0.8px solid #8bc61c;
  box-shadow: 2px 2px 4px #8bc61c;
}
@media (max-width: 550px) {
  .cont__service_description {
    width: 95%;
    margin: 10% auto;
    padding: 20px;
  }
}
.cont__service_description > h2 {
  text-transform: uppercase;
  text-align: left;
  color: #1d1d1d;
}
.cont__service_description > p {
  color: #1d1d1d;
  text-align: left;
  font-weight: 500;
}
.cont__service_list {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 550px) {
  .cont__service_list {
    flex-direction: column;
  }
}
.cont__service_list > li {
  width: 45%;
  margin: 2.5% 0;
  padding: 10px;
  border: 2px solid #8bc61c;
  border-radius: 15px;
  transition: background-color 0.7s, transform 0.8s, border 0.7s;
}
@media (max-width: 550px) {
  .cont__service_list > li {
    width: 100%;
  }
}
.cont__service_list > li:hover {
  transform: scale(0.9);
  background-color: #003f95;
  border: 2px solid #003f95;
}
.cont__service_list > li:hover > a {
  color: #fff;
}
.cont__service_list > li > a {
  color: #8bc61c;
  text-decoration: none;
  transition: color 0.7s;
}

