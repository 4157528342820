@import './variables.scss';

.cont__footer {
    width: 100%;
    height: fit-content;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 550px){
        flex-direction: column;
    }

    &_mission {
        @include sectionColored(35px, $second_color);

        & > h2, p{
            color: #fff;
        }

        & > p {
            font-size: 21px;
            line-height: 25px;
        }
    }

    & img {
        width: 250px;
        object-fit: contain;

        @media(max-width: 550px){
            width: 225px;
        }
    }

    & ul {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        list-style-type: none;
        padding-inline-start: 0;

        @media(max-width: 550px){
            flex-direction: column;
            padding: 15px 0;
        }

        & > li {
            margin: 0 2.5%;
            width: 25%;
            
            @media(max-width: 550px){
                width: 95%;
                margin: 2.5% 0;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
            }

            & a[id="preventivo"]{
                background-color: transparent;
                color: #8bc61c;
                border: 1px solid #8bc61c;
                padding: 15px;
                border-radius: 15px;
                box-shadow: 2px 2px 3px #dedede;
                cursor: pointer;
                transition: all .6s;
                text-transform: uppercase;
                font-weight: bold;
                margin-top: 10px;

                &:hover {
                    background-color: #8bc61c;
                    color: #fff;
                }
            }

            &[id="info"]{
                flex-direction: column;
                font-size: 11px;

                & a {
                    margin: 0;
                    font-weight: bold;
                }

                & > img {
                    width: 35px;
                    height: 35px;
                    object-fit: contain;
                    margin-bottom: 2%;
                }
            }
            
            & a {
                text-decoration: none;
                color: #8bc61c;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform .6s;
                margin: 10px 0;
                
                &:hover {
                    transform: translateX(5px);
                }

                & > img {
                    width: 35px;
                    height: 35px;
                    object-fit: contain;
                }

                & > span {
                    margin-left: 5px;
                    font-weight: bold;
                }
            }
        }
    }
}