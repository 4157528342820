.cont__about {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont__about_description {
  width: 85%;
  height: fit-content;
  margin: 3% 0;
  padding: 35px;
  border-radius: 15px;
  border: 0.8px solid #8bc61c;
  box-shadow: 2px 2px 4px #8bc61c;
}
@media (max-width: 550px) {
  .cont__about_description {
    width: 95%;
    margin: 10% auto;
    padding: 20px;
  }
}
.cont__about_description > h2 {
  text-transform: uppercase;
  text-align: left;
  color: #1d1d1d;
}
.cont__about_description > p {
  color: #1d1d1d;
  text-align: left;
  font-weight: 500;
}
.cont__about_extraImage {
  width: 50px;
  object-fit: contain;
  margin-top: 5%;
}
.cont__about_enti {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  align-items: center;
  padding-inline-start: 0;
  list-style: none;
}
@media (max-width: 550px) {
  .cont__about_enti {
    flex-direction: column;
  }
}
.cont__about_enti > li {
  width: 45%;
}
@media (max-width: 550px) {
  .cont__about_enti > li {
    width: 95%;
  }
}
.cont__about_enti > li > p {
  text-align: center;
}
.cont__about_enti > li > img {
  width: 250px;
  height: 125px;
  object-fit: contain;
}
.cont__about_certificazioni {
  width: 85%;
  height: fit-content;
  padding-inline-start: 0%;
  list-style: none;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
@media (max-width: 550px) {
  .cont__about_certificazioni {
    flex-direction: column;
  }
  .cont__about_certificazioni > li {
    width: 100% !important;
  }
}
.cont__about_certificazioni > li {
  width: 18%;
}
.cont__about_certificazioni > li > img {
  width: 100%;
  object-fit: contain;
}
.cont__about_certificationList {
  width: 100%;
  height: fit-content;
  padding: 50px;
  background-color: #003f95;
  margin-top: 5%;
  box-shadow: inset 2px 3px 3px #6e66668c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cont__lavori {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.cont__lavori > div {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 3px dotted #003f95;
  padding-bottom: 30px;

  & > h3 {
    color: #8bc61c;
    font-weight: 400;
    letter-spacing: 1px;
  }

  & > p > strong {
    color: #003f95;
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    & > img {
      width: 45%;
      height: 200px;
      object-fit: cover;
      border-radius: 6px;
      box-shadow: 0px 0px 8px #666;
      margin-bottom: 5%;
    }
  }
}

@media (max-width: 550px) {
  .cont__about_certificationList {
    padding: 25px 35px;
  }
}
.cont__about_certificationList > li {
  margin: 10px 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 550px) {
  .cont__about_certificationList > li {
    width: 80%;
  }
}
.cont__about_certificationList > li > ul {
  padding-inline-start: 50px !important;
}
@media (max-width: 550px) {
  .cont__about_certificationList > li > ul {
    padding-inline-start: 0 !important;
  }
}

