.cont__services {
  width: 85%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding-inline-start: 0;
}
@media (max-width: 550px) {
  .cont__services {
    flex-direction: column;
    width: 95%;
  }
}
.cont__services > a {
  text-decoration: none;
  width: 30%;
  color: #1d1d1d;
}
@media (max-width: 550px) {
  .cont__services > a {
    width: 100%;
  }
}
.cont__services > a > li {
  width: 100%;
  min-height: 300px;
  height: fit-content;
  border-radius: 15px;
  border: 0.8px solid #8bc61c;
  box-shadow: 2px 2px 4px #dedede;
  padding: 10px 20px;
  margin: 5% 0;
  transition: transform 0.8s ease-in-out, box-shadow 0.7s ease-in-out;
  cursor: pointer;
}
.cont__services > a > li:hover {
  transform: scale(0.9);
  box-shadow: 0.5px 1px 2px #8bc61c;
}
.cont__services > a > li > h3 {
  color: #8bc61c;
  text-align: left;
  font-size: 16px;
}
.cont__services > a > li > p {
  text-align: left;
  line-height: 30px;
  font-weight: 500;
}

