@import './variables.scss';

.cont__header {
    width: 100%;
    height: 125px;
    box-shadow: 0px 2px 3px #dedede;
    padding: 5px;
    display: flex;
    align-items: center;

    @media(max-width: 550px){
        justify-content: space-evenly;
        height: 115px;
        padding: unset;
    }

    &_mobile {
        width: 30px;
        height: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        z-index: 9999;

        @media(min-width: 1024px){
            display: none;
        }

        &_list {
            width: 100vw;
            min-height: 75vh;
            height: fit-content;
            position: absolute;
            background-color: #003f95;
            transition: transform .7s;
            padding: 35px;
            display: flex;
            align-items: center;
            flex-direction: column;
            z-index: 9997;
            margin: 0;

            & > li {
                width: 80%;

                & > a {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    text-decoration: none;
                    font-weight: bold;

                    & > img {
                        width: 35px;
                        height: 35px;
                        object-fit: contain;
                        margin-right: 15px;
                    }
                }
            }
        }
        
        &:hover {
            & > span {
                &:first-of-type, &:last-of-type {
                    transform: translateX(0)
                }
            }
        }

        & > span {
            width: 100%;
            height: 5px;
            border-radius: 10px;
            transition: transform .7s;

            &:first-of-type, &:last-of-type {
                transform: translateX(-5px)
            }
        }
    }

    &_logo {
        height: 80%;

        & img {
            height: 100%;
            object-fit: cover;
        }
    }

    &_list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media(max-width: 550px){
            display: none;
        }

        & > li {
            width: 100px;
            display: flex;
            flex-direction: column;
            transition: transform .7s;

            &:hover {
                transform: translateY(-5px);

                & > a > p {
                    background-color: $second_color;
                    border: .8px solid $second_color;
                    color: #fff;
                }
            }

            & > a {
                text-decoration: none;

                & > p {
                    border: .8px solid #8bc61c;
                    color: #8bc61c;
                    font-weight: bold;
                    text-transform: uppercase;
                    border-radius: 25px;
                    margin-top: 5px;
                    padding: 10px;
                    font-size: 11px;
                    transition: background-color .7s, border .8s;
                    box-shadow: 0px 2px 4px #dedede;

                    &[id="work"] {
                        font-size: 9px;
                    }
                }
            }
        }

        & img {
            height: 30px;
            object-fit: contain;
        }
    }
}