.cont__header {
  width: 100%;
  height: 125px;
  box-shadow: 0px 2px 3px #dedede;
  padding: 5px;
  display: flex;
  align-items: center;
}
@media (max-width: 550px) {
  .cont__header {
    justify-content: space-evenly;
    height: 115px;
    padding: unset;
  }
}
.cont__header_mobile {
  width: 30px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 9999;
}
@media (min-width: 1024px) {
  .cont__header_mobile {
    display: none;
  }
}
.cont__header_mobile_list {
  width: 100vw;
  min-height: 75vh;
  height: fit-content;
  position: absolute;
  background-color: #003f95;
  transition: transform 0.7s;
  padding: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 9997;
  margin: 0;
}
.cont__header_mobile_list > li {
  width: 80%;
}
.cont__header_mobile_list > li > a {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
.cont__header_mobile_list > li > a > img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin-right: 15px;
}
.cont__header_mobile:hover > span:first-of-type, .cont__header_mobile:hover > span:last-of-type {
  transform: translateX(0);
}
.cont__header_mobile > span {
  width: 100%;
  height: 5px;
  border-radius: 10px;
  transition: transform 0.7s;
}
.cont__header_mobile > span:first-of-type, .cont__header_mobile > span:last-of-type {
  transform: translateX(-5px);
}
.cont__header_logo {
  height: 80%;
}
.cont__header_logo img {
  height: 100%;
  object-fit: cover;
}
.cont__header_list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 550px) {
  .cont__header_list {
    display: none;
  }
}
.cont__header_list > li {
  width: 100px;
  display: flex;
  flex-direction: column;
  transition: transform 0.7s;
}
.cont__header_list > li:hover {
  transform: translateY(-5px);
}
.cont__header_list > li:hover > a > p {
  background-color: #003f95;
  border: 0.8px solid #003f95;
  color: #fff;
}
.cont__header_list > li > a {
  text-decoration: none;
}
.cont__header_list > li > a > p {
  border: 0.8px solid #8bc61c;
  color: #8bc61c;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 25px;
  margin-top: 5px;
  padding: 10px;
  font-size: 11px;
  transition: background-color 0.7s, border 0.8s;
  box-shadow: 0px 2px 4px #dedede;
}
.cont__header_list > li > a > p[id=work] {
  font-size: 9px;
}
.cont__header_list img {
  height: 30px;
  object-fit: contain;
}

