@import './variables.scss';

.cont__service {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_description {
        @include sectionDescription();
    }

    &_list {
        width: 65%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media(max-width: 550px){
            flex-direction: column;
        }

        & > li {
            width: 45%;
            margin: 2.5% 0;
            padding: 10px;
            border: 2px solid $main_color;
            border-radius: 15px;
            transition: background-color .7s, transform .8s, border .7s;

            @media(max-width: 550px){
                width: 100%;
            }
            
            &:hover {
                transform: scale(.9);
                background-color: $second_color;
                border: 2px solid $second_color;

                & > a {
                    color: #fff;
                }
            }

            & > a {
                color: $main_color;
                text-decoration: none;
                transition: color .7s;
            }
        }
    }
}