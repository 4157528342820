@import './variables.scss';

.cont__intro {
    width: 100%;
    height: 65vh;
    background-color: $main_color;
    box-shadow: 0px 4px 3px #dedede;

    @media(max-width: 550px){
        height: 50vh;

        &_slide { 
            height: 50vh;
        }
    }

    &_slide {
        width: 100%;
        height: 65vh;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        background-size: cover;
        background-position: center center;

        & h2 {
            color: #fff;
            font-size: 36px;
            text-transform: uppercase;
            width: 60%;
            text-align: left;
            text-shadow: 0px 2px 3px #1d1d1d;
            line-height: 60px;

            & span {
                color: $main_color;
            }
        }

        & h2, a {
            margin-left: 10%;
        }

        & a {
            color: #fff;
            text-decoration: none;  
            font-weight: bold;
            text-transform: uppercase;
            padding: 15px 35px;
            border: 2px solid #fff;
            border-radius: 15px;
            transition: background-color 1s;
            box-shadow: 1px 3px 2px #1d1d1d;
            max-width: 200px;

            &:hover {
                background-color: $second_color;
            }
        }

        @media(max-width: 550px){
            height: 50vh;
        }
    }
}