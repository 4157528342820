.cont__home {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont__home_sectionDescription {
  width: 85%;
  height: fit-content;
  margin: 3% 0;
  padding: 35px;
  border-radius: 15px;
  border: 0.8px solid #8bc61c;
  box-shadow: 2px 2px 4px #8bc61c;
}
@media (max-width: 550px) {
  .cont__home_sectionDescription {
    width: 95%;
    margin: 10% auto;
    padding: 20px;
  }
}
.cont__home_sectionDescription > h2 {
  text-transform: uppercase;
  text-align: left;
  color: #1d1d1d;
}
.cont__home_sectionDescription > p {
  color: #1d1d1d;
  text-align: left;
  font-weight: 500;
  line-height: 25px;
}
.cont__home_sectionCollabs {
  width: 85%;
  margin: 2.5% auto;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline-start: 0;
  list-style: none;
}
@media (max-width: 550px) {
  .cont__home_sectionCollabs {
    width: 95%;
    margin: 10% auto;
    flex-direction: column;
    align-items: center;
  }
}
.cont__home_sectionCollabs_image {
  width: 50px;
  object-fit: contain;
  margin-top: 2.5%;
}
.cont__home_sectionCollabs_image + h2 {
  color: #8bc61c;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 25px;
}
.cont__home_sectionCollabs > li {
  width: 15%;
  height: 100px;
  margin: 2.5% 0;
}
@media (max-width: 550px) {
  .cont__home_sectionCollabs > li {
    width: 80%;
  }
}
.cont__home_sectionCollabs > li > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cont__home_sectionNews {
  width: 85%;
  margin: 2.5% auto;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline-start: 0;
  list-style: none;
  margin: 2.5% auto;
}
@media (max-width: 550px) {
  .cont__home_sectionNews {
    width: 95%;
    margin: 10% auto;
    flex-direction: column;
    align-items: center;
  }
}
.cont__home_sectionNews > li {
  width: 30%;
  height: 225px;
  margin: 2.5% 0;
  box-shadow: 2px 3px 5px #dedede;
  padding: 5px 0;
  transition: transform 0.8s ease-in-out, box-shadow 0.7s ease-in-out;
  cursor: pointer;
}
@media (max-width: 550px) {
  .cont__home_sectionNews > li {
    width: 90%;
    margin: 10% 0;
  }
}
.cont__home_sectionNews > li:hover {
  transform: scale(0.9);
  box-shadow: 0.5px 1px 2px #dedede;
}
.cont__home_sectionNews > li > a > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cont__home_sectionFAQ {
  width: 85%;
  margin: 2.5% auto;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline-start: 0;
  list-style: none;
}
@media (max-width: 550px) {
  .cont__home_sectionFAQ {
    width: 95%;
    margin: 10% auto;
    flex-direction: column;
    align-items: center;
  }
}
.cont__home_sectionFAQ > li {
  width: 30%;
  min-height: 250px;
  height: fit-content;
  margin: 2.5% auto;
  padding: 5px 15px;
}
@media (max-width: 550px) {
  .cont__home_sectionFAQ > li {
    width: 95%;
  }
}
.cont__home_sectionFAQ > li[id=inside] {
  box-shadow: 0px 4px 3px #dedede;
}
.cont__home_sectionFAQ > li > h3 {
  color: #8bc61c;
  text-transform: uppercase;
  font-size: 16px;
}
.cont__home_sectionFAQ > li > p {
  color: #666;
  font-size: 14px;
}

